import React from "react";
import { useAuth } from "../context/auth-context";
import { isUser } from "../lib/isUser";
import { DashboardTemplate } from "../components/dashbord/dasbbord-template";

const Home = () => {
  const { email } = useAuth() as any;
  const [userId, setUserId] = React.useState(null);

  React.useEffect(() => {
    const fetchUserId = async () => {
      if (email != "getall") {
        const result = await isUser(email, null);
        setUserId(result[0].user_id);
      } else {
        setUserId(email);
      }
    };

    fetchUserId();
  }, []);
  return <DashboardTemplate userId={userId} />;
};
export default Home;
